

const DataPrivacyPage = () => {
    return (
        <>

            <h1>Ochrana osobných údajov</h1>
            <p>Vaše súkromie je pre nás veľmi dôležité. Preto sme vypracovali tento dokument, aby sme vám vysvetlili, ako zhromažďujeme, používame, zverejňujeme a chránime vaše osobné údaje.</p>

            <h2>Zhromažďovanie údajov</h2>
            <p>Zhromažďujeme rôzne typy informácií, vrátane:</p>
            <ul>
                <li>Technické údaje: typ prehliadača.</li>
                <li>Údaje o používaní: informácie o tom, ako používate našu webovú stránku.</li>
            </ul>

            <h2>Používanie údajov</h2>
            <p>Vaše údaje používame na:</p>
            <ul>
                <li>Poskytovanie a zlepšovanie našich služieb.</li>
                <li>Komunikáciu s vami.</li>
                <li>Analýzu a výskum s cieľom zlepšiť našu webovú stránku.</li>
            </ul>

            <h2>Zverejňovanie údajov</h2>
            <p>Vaše osobné údaje nezverejňujeme tretím stranám, okrem prípadov, keď je to nevyhnutné na poskytovanie našich služieb alebo keď to vyžaduje zákon.</p>

            <h2>Ochrana údajov</h2>
            <p>Používame rôzne bezpečnostné opatrenia na ochranu vašich osobných údajov pred neoprávneným prístupom, zmenou, zverejnením alebo zničením.</p>

            <h2>Vaše práva</h2>
            <p>Máte právo na prístup k svojim osobným údajom, ich opravu alebo vymazanie. Ak máte akékoľvek otázky alebo obavy týkajúce sa našej politiky ochrany osobných údajov, neváhajte nás kontaktovať.</p>

            <h2>Kontakt</h2>
            <p>Ak máte akékoľvek otázky týkajúce sa ochrany osobných údajov, môžete nás <a href="https://www.facebook.com/profile.php?id=61565640153822">kontaktovať</a>.</p>

        </>
    );
};

export default DataPrivacyPage;