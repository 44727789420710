import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';

import TopTab from './components/category_tabs/TopTab';
import TechnologyTab from './components/category_tabs/TechnologyTab';
import ScienceTab from './components/category_tabs/ScienceTab';
import CarsTab from './components/category_tabs/CarsTab';
import EntertainmentTab from './components/category_tabs/EntertainmentTab';
import ReviewsTab from './components/category_tabs/ReviewsTab';
import SecurityTab from './components/category_tabs/SecurityTab';
import PoliticsTab from './components/category_tabs/PoliticsTab';
import HealthTab from './components/category_tabs/HealthTab';
import OtherTab from './components/category_tabs/OtherTab';
import ArticleV1 from './components/ArticleV1';
import ArticleV2 from './components/ArticleV2';
import AppNavbar from './components/AppNavbar';
import SidePanel from './components/SidePanel';
import AdminTable from './components/admin_tabs/AdminTable';
import AdminArticle from './components/admin_tabs/AdminArticle';
import LoginPage from './components/admin_tabs/LoginPage';
import DataPrivacyPage from './components/DataPrivacyPage';
import './styles/App.css';
import UsePageTracking from './components/UsePageTracking';

// Initialize Google Analytics only in production
if (process.env.REACT_APP_ENV === 'PROD') {
  const TRACKING_ID = 'G-PYV4X93V3F';
  ReactGA.initialize(TRACKING_ID);
}

function App() {
  

  return (
    <HelmetProvider>
      <BrowserRouter>
      <UsePageTracking />
        <div className="d-flex flex-column h-100">
          <AppNavbar />
          <div className="d-flex flex-grow-1">
            <SidePanel position="left" />
            <div className="flex-grow-1 p-3 main-content">
              <Routes>
                <Route path="/" element={<TopTab />} />
                <Route path="/technika" element={<TechnologyTab />} />
                <Route path="/veda" element={<ScienceTab />} />
                <Route path="/auta" element={<CarsTab />} />
                <Route path="/zabava" element={<EntertainmentTab />} />
                <Route path="/recenzie" element={<ReviewsTab />} />
                <Route path="/itbezpecnost" element={<SecurityTab />} />
                <Route path="/politika" element={<PoliticsTab />} />
                <Route path="/zdravie" element={<HealthTab />} />
                <Route path="/ostatne" element={<OtherTab />} />
                <Route path="/article/:category/:id" element={<ArticleV2 />} />
                <Route path="/article/:id" element={<ArticleV1 />} />
                <Route path="/admin" element={<AdminTable />} />
                <Route path="/admin/article/:id" element={<AdminArticle />} />
                <Route path="/admin/login" element={<LoginPage />} />
                <Route path="/data-privacy" element={<DataPrivacyPage />} />
              </Routes>
            </div>
            <SidePanel position="right" />
          </div>
        </div>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;


