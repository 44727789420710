
import TabArticles from '../TabArticles';
import SeoUtil from '../SeoUtil';

const CarsTab = () => {
  const tab_title = 'Novinky zo sveta áut'
  const url_category = 'cars'

  

  return (<>
    <SeoUtil
      title='Mudrosti Sveta | Novinky zo sveta áut'
      description={tab_title}
      name='Mudrosti Sveta'
      type='articles' />
    <TabArticles tab_title={tab_title} url_category={url_category} />
  </>
  );
};

export default CarsTab;