import { useLocation } from 'react-router-dom';


const SidePanel = ({ position }) => {

  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  if (isAdminRoute) {
    return null;
  }

  return (

    <div className={`side-panel ${position} bg-light p-3 min-width-sm min-width-md`}>
      {/* <p>Ad Space {isAdminRoute}</p> */}
    </div>
  );
}

export default SidePanel;