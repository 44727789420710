
import TabArticles from '../TabArticles';
import SeoUtil from '../SeoUtil';


const ScienceTab = () => {
  const tab_title = 'Novinky zo sveta vedy'
  const url_category = 'science'

  return (<>
    <SeoUtil
      title={`Mudrosti Sveta | ${tab_title}`}
      description={tab_title}
      name='Mudrosti Sveta'
      type='articles' />
    <TabArticles tab_title={tab_title} url_category={url_category} />
  </>
  );
};

export default ScienceTab;