import React from 'react';
import { Facebook } from 'react-bootstrap-icons';

const Footer = () => {
    return (
        <footer className="bg-dark text-white text-center py-3">
            <div className="container">
                <a href="https://www.facebook.com/profile.php?id=61565640153822" className="text-white me-3">
                    <Facebook size={30} />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61565640153822" className="text-white me-3">
                    Kontakt
                </a>
                <a href="/data-privacy" className="text-white">
                    Ochrana osobných údajov
                </a>
            </div>
        </footer>
    );
};

export default Footer;