import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Spinner, Alert} from 'react-bootstrap';
import ArticleList from '../ArticleList';
import SeoUtil from '../SeoUtil';
import Footer from '../Footer';

const TopTab = () => {
    const [articlesByCategory, setArticlesByCategory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const category_order = ['technology', 'science', 'politics', 'cars', 'reviews', 'health', 'security', 'entertainment', 'other']
    const category_order_sk = ['Technika', 'Veda', 'Politika', 'Autá', 'Recenzie', 'Zdravie', 'IT bezpečnosť', 'Zábava', 'Ostatné']


    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/toparticles`;
                const response = await axios.get(url);
                setArticlesByCategory(response.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchArticles();
    }, []);



    if (loading) {
        return (
            <Container className="text-center mt-5">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        );
    }



    if (error) {
        return (
            <Container className="mt-5">
                <Alert variant="danger">Error: {error}</Alert>
            </Container>
        );
    }


    return (<>
        <SeoUtil
            title={`Mudrosti Sveta | Top články`}
            description='Top články a novinky na rôzne témy'
            name='Mudrosti Sveta'
            type='articles' />

        <h1 className='text-center'>Top články v každej kategórii</h1>
        <hr />
        {category_order.map((category, index) => (
            articlesByCategory[category] &&
            (<div key={category}>
                <div className="jumbotron text-center bg-primary text-white custom-jumbotron">
                    <div className="display-6 custom-heading">{category_order_sk[index]}</div>
                </div>
                <ArticleList articles={articlesByCategory[category]} />
            </div>)
        ))}

        <Footer />



    </>
    );
};

export default TopTab;