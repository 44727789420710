import { Card, Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ArticleList = ({ articles }) => {


  return (
    <div className="container mt-4">
      {articles.map((article, index) => (
        <article key={article.RowKey} className="row mb-4">
          <div className="col-md-4">
            <Link to={`/article/${article.PartitionKey}/${article.url_id_sk}`}> 
              <img src={article.img} className="img-fluid" alt={article.title_sk} />
            </Link>
          </div>
          <div className="col-md-8">
            <header>
              <Link to={`/article/${article.PartitionKey}/${article.url_id_sk}`} className="text-decoration-none custom-link">
                <h2 className="title-red h4">{article.title_sk}</h2>
              </Link>
              <p><small className="text-muted">Vytvorené: {article.created_at.substring(0, 10)}</small></p>
            </header>
            <p><span className="badge bg-success">{article.subcategory_sk || article.subcategory}</span></p>
          </div>
          {index < articles.length - 1 && <hr />}
        </article>
      ))}
    </div>
  );
};

export default ArticleList;