import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation } from 'react-router-dom';
import icon from '../logo.png'

const AppNavbar = (props) => {

  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location]);

  const handleSelect = (eventKey) => {
    setActiveTab(eventKey);
  };

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
      {/* <Navbar expand="lg" className="bg-body-tertiary fixed-top"> */}
        <Container>
          <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
            <img
              src={icon}
              alt="Logo"
              width="50"
              height="50"
              className="d-inline-block align-top"
            />
            <span className="ms-2">Múdrosti Sveta</span>

          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" activeKey={activeTab} onSelect={handleSelect}>
              <Nav.Link as={Link} to="/" eventKey="/" className={activeTab === '/' ? 'active-tab' : ''}>Top</Nav.Link>
              <Nav.Link as={Link} to="/itbezpecnost" eventKey="/itbezpecnost" className={activeTab === '/itbezpecnost' ? 'active-tab' : ''}>IT Bezpečnosť</Nav.Link>
              <Nav.Link as={Link} to="/technika" eventKey="/technika" className={activeTab === '/technika' ? 'active-tab' : ''}>Technika</Nav.Link>
              <Nav.Link as={Link} to="/veda" eventKey="/veda" className={activeTab === '/veda' ? 'active-tab' : ''}>Veda</Nav.Link>
              <Nav.Link as={Link} to="/auta" eventKey="/auta" className={activeTab === '/auta' ? 'active-tab' : ''}>Autá</Nav.Link>
              <Nav.Link as={Link} to="/zabava" eventKey="/zabava" className={activeTab === '/zabava' ? 'active-tab' : ''}>Zábava</Nav.Link>
              <Nav.Link as={Link} to="/recenzie" eventKey="/recenzie" className={activeTab === '/recenzie' ? 'active-tab' : ''}>Recenzie</Nav.Link>
              <Nav.Link as={Link} to="/politika" eventKey="/politika" className={activeTab === '/politika' ? 'active-tab' : ''}>Politika</Nav.Link>
              <Nav.Link as={Link} to="/zdravie" eventKey="/zdravie" className={activeTab === '/zdravie' ? 'active-tab' : ''}>Zdravie</Nav.Link>
              <Nav.Link as={Link} to="/ostatne" eventKey="/ostatne" className={activeTab === '/ostatne' ? 'active-tab' : ''}>Ostatné</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AppNavbar;