import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { Container, Spinner, Alert, Row, Col } from 'react-bootstrap';

import ArticleList from './ArticleList';


const TabArticles = (props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    // the page is represented by timestamp of the oldest article on the previous page. null for first page. YYYY-mm-dd hh:mm:ss for next pages.
    const page_timestamp = searchParams.get('last_ts');
    const tab_title = props.tab_title
    const url_category = props.url_category

    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchArticle = async () => {
          try {
            let url;
            if (page_timestamp) {
                url = process.env.REACT_APP_BACKEND_URL + `/api/v1/articles/${url_category}?last_ts=${page_timestamp}`
            } else {
                url = process.env.REACT_APP_BACKEND_URL + `/api/v1/articles/${url_category}`;
            }
            const response = await axios.get(url);

            setArticles(response.data);
          } catch (error) {
            setError(error.message);
          } finally {
            setLoading(false);
          }
        };
    
        fetchArticle();
      }, [page_timestamp]);


    const handleNextPage = () => {
        const lastArticle = articles[articles.length - 1];
        if (lastArticle) {
            navigate(`?last_ts=${lastArticle.created_at}`);
            window.scrollTo(0, 0);
        }
    };


    if (loading) {
        return (
          <Container className="text-center mt-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Container>
        );
      }
    
    
    
      if (error) {
        return (
          <Container className="mt-5">
            <Alert variant="danger">Error: {error}</Alert>
          </Container>
        );
      }
    

    return (<>
        <h1 className='text-center'>{tab_title}</h1>
        <hr />
        <ArticleList articles={articles} />
        <div className="text-center">
            <button className="btn btn-primary" onClick={handleNextPage}>Ďalšia strana</button>
        </div>
    </>
    );
};

export default TabArticles;