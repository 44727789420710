import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const UsePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'PROD') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }
  }, [location]);

  return null; // Ensure the component returns null or a valid JSX element
};

export default UsePageTracking;