import React from 'react';
import { Helmet } from 'react-helmet-async';
import icon from '../logo.png'

export default function SeoUtil({ title, description, name, type, published_time = false, image = false, canonical_url = false }) {
    return (
        <Helmet>
            { /* Standard metadata tags */}
            <title>{title}</title>
            <meta name='description' content={description} />
            { /* End standard metadata tags */}
            { /* Facebook tags */}
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image ? image : '/logo.jpg'} />
            { /* End Facebook tags */}
            { /* Twitter tags */}
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image ? image : '/logo.jpg'} />
            { /* End Twitter tags */}
            {published_time && (
                <meta property="article:published_time" content={published_time} />
            )}
            {canonical_url && (
                <link rel="canonical" href={`https://mudrostisveta.top/${canonical_url}`} />
            )}

        </Helmet>
    )
}