import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Spinner, Alert, Button } from 'react-bootstrap';
import {axiosAT} from '../../interceptors/axiosInter'


function AdminArticle() {
    const { id } = useParams();
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isEditingContent, setIsEditingContent] = useState(false);
    const [editableTitle, setEditableTitle] = useState('');
    const [editableContent, setEditableContent] = useState('');
    const editableTitleRef = useRef(null);
    const editableContentRef = useRef(null);


    useEffect(() => {
        fetchArticle();
    }, [id]);


    const fetchArticle = async () => {
        try {
            const response = await axiosAT.get(process.env.REACT_APP_BACKEND_URL + `/api/v1/admin/article/${id}`);
            setArticle(response.data);
            setEditableTitle(response.data.title_sk)
            setEditableContent(response.data.body_processed)
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleEditTitle = () => {
        setIsEditingTitle(true);
    };

    const handleSaveTitle = () => {
        const newTitle = editableTitleRef.current.innerText;
        setEditableTitle(newTitle);
        setIsEditingTitle(false);
        updateTitlePostRequest(newTitle)
    };

    const handleEditContent = () => {
        setIsEditingContent(true);
    };

    const handleSaveContent = () => {
        const newContent = editableContentRef.current.innerHTML
        setEditableContent(newContent);
        setIsEditingContent(false);
        updateBodyPostRequest(newContent)
    };

    const handleApprove = () => {
        console.log('Approved');
        updateApprovalPostRequest()
    };

    const handleReject = () => {
        console.log('Rejected');
        updateStatusPostRequest()
    };


    const updateTitlePostRequest = async (title) => {
        const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/admin/changetitle`;
        const data = {
            "PartitionKey": article.PartitionKey,
            "RowKey": article.RowKey,
            "title_sk": title
        };
        try {
            const response = await axiosAT.post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('update response', response)
        } catch (error) {
            console.error('There was a problem with the axios operation:', error);
        }
    };


    const updateBodyPostRequest = async (body) => {
        const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/admin/changebody`;
        const data = {
            "PartitionKey": article.PartitionKey,
            "RowKey": article.RowKey,
            "body_processed": body
        };
        try {
            const response = await axiosAT.post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('update response', response)
        } catch (error) {
            console.error('There was a problem with the axios operation:', error);
        }
    };

    const updateApprovalPostRequest = async () => {
        const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/admin/approve`;
        const data = {
            "PartitionKey": article.PartitionKey,
            "RowKey": article.RowKey,
            "approved": true
        };
        try {
            const response = await axiosAT.post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('update response', response)
            fetchArticle()
        } catch (error) {
            console.error('There was a problem with the axios operation:', error);
        }
    };


    const updateStatusPostRequest = async () => {
        const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/admin/changestatus`;
        const data = {
            "PartitionKey": article.PartitionKey,
            "RowKey": article.RowKey,
            "status": "rejected"
        };
        try {
            const response = await axiosAT.post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('update response', response)
            fetchArticle()
        } catch (error) {
            console.error('There was a problem with the axios operation:', error);
        }
    };



    if (loading) {
        return (
            <Container className="text-center mt-5">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        );
    }



    if (error) {
        return (
            <Container className="mt-5">
                <Alert variant="danger">Error: {error}</Alert>
            </Container>
        );
    }



    return (
        <>
            <div>
                <h1 ref={editableTitleRef} suppressContentEditableWarning={true} contentEditable={isEditingTitle} className={isEditingTitle ? 'border p-2' : ''}>
                    {editableTitle}
                </h1>
                {isEditingTitle ? (
                    <Button variant="secondary" onClick={handleSaveTitle} className="mx-2">Save</Button>
                ) : (
                    <Button variant="warning" onClick={handleEditTitle} className="mx-2">Change Title</Button>
                )}


                {/* Your article content goes here */}
            </div>

            <img src={article.img} class="img-fluid" alt=""></img>

            <Container className="mt-5">
                <div
                    ref={editableContentRef}
                    contentEditable={isEditingContent}
                    dangerouslySetInnerHTML={{ __html: editableContent }}
                    className={isEditingContent ? 'border p-2' : ''}
                />
            </Container>

            <div>Admin Article ID: {article.RowKey}</div>
            <div>Admin Article status: {article.status}</div>
            <div>approved: {article.approved ? 'Yes' : 'No'}</div>
            <div>model_body: {article.model_body}</div>
            <div>model_title: {article.model_title}</div>
            <p>Vytvorene podla: <a href={article.article_url_source} >zdroj</a></p>

            <div className="d-flex justify-content-center mt-3">
                {isEditingContent ? (
                    <Button variant="secondary" onClick={handleSaveContent} className="mx-2">Save</Button>
                ) : (
                    <Button variant="warning" onClick={handleEditContent} className="mx-2">Edit</Button>
                )}
                <Button variant="success" onClick={handleApprove} className="mx-2">Approve</Button>
                <Button variant="danger" onClick={handleReject} className="mx-2">Reject</Button>
                <Link to={`/admin`} >
                    <Button variant="primary" className="mx-2">Next</Button>
                </Link>

            </div>


        </>

    );
}


export default AdminArticle;