
import TabArticles from '../TabArticles';
import SeoUtil from '../SeoUtil';


const ReviewsTab = () => {
  const tab_title = 'Recenzie'
  const url_category = 'reviews'

  return (<>
    <SeoUtil
      title={`Mudrosti Sveta | ${tab_title}`}
      description='Recenzie a produkty'
      name='Mudrosti Sveta'
      type='articles' />
    <TabArticles tab_title={tab_title} url_category={url_category} />
  </>
  );
};

export default ReviewsTab;