import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Spinner, Alert, Row, Col } from 'react-bootstrap';
import SeoUtil from './SeoUtil';

function ArticleV2() {
  const { category, id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `/api/v1/article/${category}/${id}`);
        setArticle(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    if (article) {
      addViewPostRequest()
    }
  }, [article]);

  const addViewPostRequest = async () => {
    const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/add_view`;
    const data = {
      "PartitionKey": article.PartitionKey,
      "RowKey": article.RowKey
    };
    try {
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // console.log('post response', response)
    } catch (error) {
      console.error('There was a problem with the axios operation:', error);
    }
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }



  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">Error: {error}</Alert>
      </Container>
    );
  }



  return (
    <>
      <SeoUtil
        title={article.title_sk}
        description={article.subtitle_sk || article.title_sk}
        name='Mudrosti Sveta'
        type='article'
        published_time={article.created_at?.substring(0, 10)}
        canonical_url={`article/${category}/${article.url_id_sk}`} />

      <article>
        <header>
          <h1>{article.title_sk}</h1>
          {article.created_at && (
            <p>Vytvorené: {article.created_at.substring(0, 10)}</p>
          )}
          <h6>{article.subtitle_sk}</h6>
        </header>

        <img src={article.img} className="img-fluid" alt={article.title_sk} />

        <Container className="mt-5">
          <div dangerouslySetInnerHTML={{ __html: article.body_processed }} />
          <footer>
            <Row className="mt-4">
              <Col>
                <p className="text-center">
                  Vytvorené podľa: <a href={article.article_url_source}>zdroj</a>
                </p>
              </Col>
            </Row>
          </footer>
        </Container>
      </article>


    </>

  );
}


export default ArticleV2;