import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {axiosAT} from '../../interceptors/axiosInter' // access token included

const AdminTable = () => {
  const navigate = useNavigate();

  const [articles, setArticles] = useState([]);
  const [filters, setFilters] = useState({
    title: '',
    title_sk: '',
    category: '',
    date_from: '',
    date_to: '',
    approved: 'false'
  });



  useEffect(() => {
    handleExecute()
  }, []);



  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleExecute = async () => {
    try {
      // Filter out empty values from filters
      const filteredFilters = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value)
      );
      const query = new URLSearchParams(filteredFilters).toString();
      const response = await axiosAT.get(process.env.REACT_APP_BACKEND_URL + `/api/v1/admin/articles?${query}`);
      setArticles(response.data);
    } catch (error) {
      console.error('Error fetching filtered articles:', error);
    }
  };

  const handleShowClick = (article) => {
    navigate(`article/${article.PartitionKey}__${article.RowKey}`);
  };

  return (
    <div className="container">
      <div className="row mb-3">
        <div className="col">
          <input
            type="text"
            className="form-control"
            name="title"
            placeholder="Title"
            value={filters.title}
            onChange={handleFilterChange}
          />
        </div>
        <div className="col">
          <input
            type="text"
            className="form-control"
            name="title_sk"
            placeholder="Title (SK)"
            value={filters.title_sk}
            onChange={handleFilterChange}
          />
        </div>
        <div className="col">
          <input
            type="text"
            className="form-control"
            name="category"
            placeholder="Category"
            value={filters.category}
            onChange={handleFilterChange}
          />
        </div>
        <div className="col">
          <input
            type="date"
            className="form-control"
            name="date_from"
            placeholder="Date From"
            value={filters.date_from}
            onChange={handleFilterChange}
          />
        </div>
        <div className="col">
          <input
            type="date"
            className="form-control"
            name="date_to"
            placeholder="Date To"
            value={filters.date_to}
            onChange={handleFilterChange}
          />
        </div>
        <div className="col">
          <select
            className="form-control"
            name="approved"
            value={filters.approved}
            onChange={handleFilterChange}
          >
            <option value="">All</option>
            <option value="true">Approved</option>
            <option value="false">Not Approved</option>
          </select>
        </div>
        <div className="col">
          <button className="btn btn-primary" onClick={handleExecute}>Execute</button>
        </div>
      </div>
      <table className="table table-striped table-responsive">
        <thead>
          <tr>
            <th>Title</th>
            <th>Title (SK)</th>
            <th>Category</th>
            <th>Created At</th>
            <th>Approved</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {articles?.map((article) => (
            <tr key={article.RowKey}>
              <td>{article.title}</td>
              <td>{article.title_sk}</td>
              <td>{article.category}</td>
              <td>{new Date(article.created_at).toLocaleDateString()}</td>
              <td>{article.approved ? 'Yes' : 'No'}</td>
              <td>
                <button className="btn btn-info" onClick={() => handleShowClick(article)}>Show</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminTable;